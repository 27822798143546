import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Paper, capitalize } from "@mui/material";
import { CSVLink } from "react-csv";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AppContext } from "../context/AppContext";
import { TimeTrackingInvoiceResponse, InvoiceResponse } from "../interfaces";
import { PageLoader } from "./Loader";
import { currencyFormatter } from "../helpers/accounting";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";
import { createInvoice } from "../api/invoice";

export const InvoiceList = () => {
    const { timeTrackingPeriodId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [invoicePeriod, setInvoicePeriod] =
        useState<TimeTrackingInvoiceResponse | null>(null);
    const csvRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}/invoice`,
                { params: { records: true } },
            );
            setInvoicePeriod(response.data as TimeTrackingInvoiceResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const rowMapper = (row: InvoiceResponse) => {
        return (
            <TableRow
                key={`${row.company.id}-${row.invoiceType}`}
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                }}
            >
                <TableCell align="center">
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            createInvoice(
                                getApi,
                                timeTrackingPeriodId,
                                row.company.id,
                                row.id,
                                `${row.company.name} - ${row.invoiceType} - ${invoicePeriod?.description}.pdf`,
                            );
                        }}
                        href="#"
                    >
                        {row.company.name}
                    </a>
                </TableCell>
                <TableCell align="center">{row.invoiceNumber}</TableCell>
                <TableCell align="center">
                    {capitalize(row.invoiceType)}
                </TableCell>
                <TableCell align="center">{row.invoiceDate}</TableCell>
                <TableCell align="center">{row.placements}</TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalRegularBill)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalOtBill)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalDtBill)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalBill)}
                </TableCell>
            </TableRow>
        );
    };

    const csvHeaders: string[] = [
        "Company",
        "Total Regular Bill",
        "Total OT Bill",
        "Total DT Bill",
        "Total Bill",
    ];

    const formatCsvRow = (record: InvoiceResponse) => {
        return [
            record.company.name,
            currencyFormatter(record.totalRegularBill),
            currencyFormatter(record.totalOtBill),
            currencyFormatter(record.totalDtBill),
            currencyFormatter(record.totalBill),
        ];
    };

    const downloadCsv = () => {
        csvRef?.current?.link.click();
    };

    return (
        <>
            <h1>Invoice Period</h1>
            {invoicePeriod && <h3>{invoicePeriod.description}</h3>}
            {!loading && invoicePeriod?.records && (
                <>
                    {" "}
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => downloadCsv()}
                    >
                        Download
                        <CSVLink
                            data={
                                invoicePeriod?.records.map(formatCsvRow) ?? []
                            }
                            headers={csvHeaders}
                            filename={`SynergyATS Invoice - ${invoicePeriod?.description}`}
                            ref={csvRef}
                            className="hidden"
                        ></CSVLink>
                    </Button>
                </>
            )}
            {loading && (
                <div>
                    <PageLoader />
                </div>
            )}
            <div className="vertical-margin"></div>
            {!loading && (
                <TableContainer component={Paper} className="spreadsheet-table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Company</TableCell>
                                <TableCell align="center">
                                    Invoice Number
                                </TableCell>
                                <TableCell align="center">
                                    Invoice Type
                                </TableCell>
                                <TableCell align="center">
                                    Invoice Date
                                </TableCell>
                                <TableCell align="center">Placements</TableCell>
                                <TableCell align="center">
                                    Total Regular Bill
                                </TableCell>
                                <TableCell align="center">
                                    Total OT Bill
                                </TableCell>
                                <TableCell align="center">
                                    Total DT Bill
                                </TableCell>
                                <TableCell align="center">Total Bill</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoicePeriod?.records?.map(rowMapper)}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
