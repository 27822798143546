import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TimeTrackingRecord } from "../../interfaces";
import { InvoiceTableRowProps } from "../../interfaces/props";
import { formatName } from "../../helpers/candidate";
import { currencyFormatter } from "../../helpers/accounting";

const textColor = "#54585B";
const styles = StyleSheet.create({
    row: {
        color: textColor,
        fontSize: 10,
        flexDirection: "row",
        alignItems: "center",
        fontStyle: "bold",
        textAlign: "center",
        paddingTop: 5,
        paddingBottom: 5,
    },
    rowAccent: {
        backgroundColor: "#F2F2F2",
    },
    service: {
        width: "15%",
    },
    description: {
        paddingLeft: "8px",
        paddingRight: "8px",
        width: "52%",
        wordWrap: "normal",
    },
    qty: {
        width: "7%",
    },
    rate: {
        width: "13%",
    },
    amount: {
        width: "13%",
    },
});

interface TableRow {
    id: string;
    service: string;
    description: string;
    qty: number;
    rate: string;
    amount: string;
}

const InvoiceTableRow = (props: InvoiceTableRowProps) => {
    const { records } = props;
    const items: TableRow[] = [];

    records.forEach((r: TimeTrackingRecord) => {
        if (r.totalBill > 0) {
            const row: TableRow = {
                id: `${r.id}-regular`,
                service: "Billable Hours",
                description: `${formatName(r.placement.candidate)} - ${
                    r.placement.job.title
                } - Regular`,
                qty: r.hours,
                rate: currencyFormatter(r.billRate ?? 0.0),
                amount: currencyFormatter(r.totalBill),
            };
            items.push(row);
        }
        if (r.totalOtBill > 0) {
            const row: TableRow = {
                id: `${r.id}-ot`,
                service: "Billable Hours",
                description: `${formatName(r.placement.candidate)} - ${
                    r.placement.job.title
                } - Overtime`,
                qty: r.otHours,
                rate: currencyFormatter(r.otBillRate ?? 0.0),
                amount: currencyFormatter(r.totalOtBill),
            };
            items.push(row);
        }
        if (r.totalDtBill > 0) {
            const row: TableRow = {
                id: `${r.id}-dt`,
                service: "Billable Hours",
                description: `${formatName(r.placement.candidate)} - ${
                    r.placement.job.title
                } - Doubletime`,
                qty: r.dtHours,
                rate: currencyFormatter((r.billRate ?? 0.0) * 2),
                amount: currencyFormatter(r.totalDtBill),
            };
            items.push(row);
        }
        if ((r.placement.directFee ?? 0) > 0) {
            const row: TableRow = {
                id: `${r.id}-direct-fee`,
                service: "Direct Placement Fee",
                description: `${formatName(r.placement.candidate)} - ${
                    r.placement.job.title
                }`,
                qty: 1,
                rate: currencyFormatter(r.totalDirectFee ?? 0),
                amount: currencyFormatter(r.totalDirectFee ?? 0),
            };
            items.push(row);
        }
    });

    const rows = items.map((item: TableRow, index: number) => {
        let rowStyle;
        if (index % 2 === 0) {
            rowStyle = { ...styles.row, ...styles.rowAccent };
        } else {
            rowStyle = styles.row;
        }

        return (
            <View style={rowStyle} key={item.id} wrap={false}>
                <Text style={styles.service}>{item.service}</Text>
                <Text style={styles.description}>{item.description}</Text>
                <Text style={styles.qty}>{item.qty}</Text>
                <Text style={styles.rate}>{item.rate}</Text>
                <Text style={styles.amount}>{item.amount}</Text>
            </View>
        );
    });
    return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
